import { useQuery } from '@tanstack/react-query';

import filterParameters from 'Lib/utilities/filterParameters';

export const queryKey = ['accounting', 'accounts'];

const useAccounts = select =>
	useQuery({
		queryKey: queryKey,
		staleTime: Infinity,
		refetchOnWindowFocus: false,
		retry: false,
		select
	});

const getAccountByID = id => useAccounts(data => data.find(({ account }) => account.accountID == id).account);

export const useAccountsWithFilters = () => {
	const { parsed } = filterParameters();

	return useQuery({
		queryKey: [...queryKey, parsed],
		staleTime: Infinity,
		refetchOnWindowFocus: false,
		retry: false
	});
};

export { useAccounts as default, getAccountByID };
