export const HistoryTypes = {
    Application: 'Application',
    Association: 'Association',
    BankAccount: 'BankAccount',
    BankFee: 'BankFee',
    BankTransfer: 'BankTransfer',
    Bill: 'Bill',
    Deposit: 'Deposit',
    DepositRelease: 'DepositRelease',
    Inspection: 'Inspection',
    JournalEntry: 'JournalEntry',
    Lease: 'Lease',
    LeaseCharge: 'LeaseCharge',
    LeaseCredit: 'LeaseCredit',
    LedgerTransfer: 'LedgerTransfer',
    ManagementFee: 'ManagementFee',
    OtherReceipt: 'OtherReceipt',
    Owner: 'Owner',
    OwnerReceipt: 'OwnerReceipt',
    Payment: 'Payment',
    PaymentBatch: 'PaymentBatch',
    PayoutReturn: 'PayoutReturn',
    Portfolio: 'Portfolio',
    Property: 'Property',
    RecurringBill: 'RecurringBill',
    RecurringJournalEntry: 'RecurringJournalEntry',
    RecurringLedgerTransfer: 'RecurringLedgerTransfer',
    Rentsign: 'Rentsign',
    Report: 'Report',
    SavedReport: 'SavedReport',
    Settlement: 'Settlement',
    Tenant: 'Tenant',
    TenantReceipt: 'TenantReceipt',
    Unit: 'Unit',
    Vendor: 'Vendor',
    VendorCredit: 'VendorCredit',
    VendorInvoice: 'VendorInvoice',
    WorkOrder: 'WorkOrder',
    WorkOrderProject: 'WorkOrderProject'
};

export const HistoryTypeInterface = {
    Portfolio: {
        title: HistoryTypes.Portfolio,
        icon: 'folder_copy'
    },
    Property: {
        title: HistoryTypes.Property,
        icon: 'home_work'
    },
    Lease: {
        title: HistoryTypes.Lease,
        icon: 'assignment'
    },
    Association: {
        title: HistoryTypes.Association,
        icon: 'groups'
    },
    Owner: {
        title: HistoryTypes.Owner,
        icon: 'import_contacts'
    },
    Tenant: {
        title: HistoryTypes.Tenant,
        icon: 'diversity_3'
    },
    Vendor: {
        title: HistoryTypes.Vendor,
        icon: 'real_estate_agent'
    },
    WorkOrder: {
        title: 'Work Order',
        icon: 'receipt_long'
    },
    Application: {
        title: HistoryTypes.Application,
        icon: 'article'
    },
    Report: {
        title: HistoryTypes.Report,
        icon: 'area_chart'
    },
    Settlement: {
        title: HistoryTypes.Settlement,
        icon: 'attach_money'
    },
    RecurringBill: {
        title: 'Recurring Bill',
        icon: 'request_quote'
    },
    RecurringJournalEntry: {
        title: 'Recurring Journal Entry',
        icon: 'request_quote'
    },
    RecurringLedgerTransfer: {
        title: 'Recurring Ledger Transfer',
        icon: 'request_quote'
    },
    Inspection: {
        title: HistoryTypes.Inspection,
        icon: 'build'
    },
    Rentsign: {
        title: HistoryTypes.Rentsign,
        icon: 'edit_note'
    },
    WorkOrderProject: {
        title: 'Work Order Project',
        icon: 'receipt_long'
    },
    Deposit: {
        title: HistoryTypes.Deposit,
        icon: 'attach_money'
    },
    DepositRelease: {
        title: 'Deposit Release',
        icon: 'attach_money'
    },
    VendorCredit: {
        title: 'Vendor Credit',
        icon: 'attach_money'
    },
    VendorInvoice: {
        title: 'Vendor Invoice',
        icon: 'attach_money'
    },
    Bill: {
        title: HistoryTypes.Bill,
        icon: 'attach_money'
    },
    TenantReceipt: {
        title: 'Tenant Receipt',
        icon: 'attach_money'
    },
    OtherReceipt: {
        title: 'Other Receipt',
        icon: 'attach_money'
    },
    OwnerReceipt: {
        title: 'Owner Receipt',
        icon: 'attach_money'
    },
    Payment: {
        title: HistoryTypes.Payment,
        icon: 'attach_money'
    },
    PaymentBatch: {
        title: 'Payment Batch',
        icon: 'payments'
    },
    PayoutReturn: {
        title: 'Payout Return',
        icon: 'attach_money'
    },
    LeaseCharge: {
        title: 'Lease Charge',
        icon: 'attach_money'
    },
    LeaseCredit: {
        title: 'Lease Credit',
        icon: 'attach_money'
    },
    LedgerTransfer: {
        title: 'Ledger Transfer',
        icon: 'attach_money'
    },
    ManagementFee: {
        title: 'Management Fee',
        icon: 'attach_money'
    },
    JournalEntry: {
        title: 'Journal Entry',
        icon: 'attach_money'
    },
    BankFee: {
        title: 'Bank Fee',
        icon: 'attach_money'
    },
    BankTransfer: {
        title: 'Bank Transfer',
        icon: 'attach_money'
    },
    BankAccount: {
        title: 'Bank Account',
        icon: 'account_balance'
    },
    Unit: {
        title: HistoryTypes.Unit,
        icon: 'apartment'
    },
    SavedReport: {
        title: 'Saved Report',
        icon: 'area_chart'
    }
};
