/**
 * Strips HTML tags from a given string and returns the plain text content.
 *
 * @param {string} string - The input string containing HTML markup. Make sure this comes from a React SyntheticEvent!
 * @returns {string} The plain text content without any HTML tags.
 */
export default function stripHtml(string) {
	if (!string || typeof string !== 'string') return '';

	// Convert the html entities to < and > respectively
	const unsafeString = string.replaceAll('&lt;', '<').replaceAll('&gt;', '>');

	// Pass the unsafe html to be parsed by a lexical analyzer
	const doc = new DOMParser().parseFromString(unsafeString, 'text/html');

	// Get only the text nodes from the DOM
	return doc.body.textContent || '';
}
