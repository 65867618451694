// Font
export const DEFAULT_FONT_STYLE = 'normal';

// Size
export const DEFAULT_LINE_HEIGHT = '24px';
export const DEFAULT_LETTER_SPACING = '0.5px';

// Navigation Menu
export const OPEN_DRAWER_WIDTH = 256;
export const CLOSED_DRAWER_WIDTH = 80;
