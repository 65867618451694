import { thunk } from 'redux-thunk';

import api from './api';
import localStorage from './local-storage';
import notify from './notify';
import promise from './promise';

import { default as dataCache } from '~/services/cache/middleware';

const createComposedMiddleware = routerMiddleware => {
	const middleware = [
		routerMiddleware,
		promise,
		api,
		thunk,
		notify,
		localStorage,
		dataCache
	];

	return middleware;
};
export default createComposedMiddleware;
