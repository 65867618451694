import { getAccountByID } from 'Lib/hooks/data/useAccounts';
import { isNil } from 'Lib/utilities/isNil';

const Account = ({ id, value: propValue, showNumber = true, showBankAccountNumber = false }) => {
	if (!id) {
		return null;
	}

	const { data: account, isFetching } = getAccountByID(id);

	if (isFetching) {
		return <span />;
	}

	let value = propValue;
	let suffix = '';

	value = account;

	if (showBankAccountNumber) {
		suffix = `xxxx-${value.bankAccountNumberTruncated}`;
	}

	return (
		!isNil(value) &&
		value.number && (
			<span>
				{showNumber ? `${value.number}:` : ''} {value.name} {suffix}
			</span>
		)
	);
};

export default Account;
