// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* mobile first technique */
.app-header-image {
    width: auto;
    height: 40px;
    display: block;
    margin: 0 auto;
}

/* the width when the ui adjust for desktop */
@media only screen and (min-width: 1044px) {
    .app-header-image {
        max-width: 100%;
        height: auto;
    }
}
`, "",{"version":3,"sources":["webpack://./lib/node/src/components/layout/components/CustomerHeader.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;IACI,WAAW;IACX,YAAY;IACZ,cAAc;IACd,cAAc;AAClB;;AAEA,6CAA6C;AAC7C;IACI;QACI,eAAe;QACf,YAAY;IAChB;AACJ","sourcesContent":["/* mobile first technique */\n.app-header-image {\n    width: auto;\n    height: 40px;\n    display: block;\n    margin: 0 auto;\n}\n\n/* the width when the ui adjust for desktop */\n@media only screen and (min-width: 1044px) {\n    .app-header-image {\n        max-width: 100%;\n        height: auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
