import { useEffect, useState } from 'react';
import MuiDrawer from '@mui/material/Drawer';

import useConfig from 'Lib/hooks/api/useConfig';
import MuiHeader from 'Lib/muiComponents/page/Header';
import { OPEN_DRAWER_WIDTH } from 'Lib/styles/variables';
import openExternalLink from 'Lib/utilities/openExternalLink';
import { screenSize } from 'Lib/utilities/screenSize';

import CustomerHeader from './components/CustomerHeader';
import Header from './components/Header';
import AppBar from './AppBar';
import Drawer from './Drawer';
import Rail from './Rail';

export default function Navigation(props) {
	const [mobileModalOpen, setMobileModalOpen] = useState(false);
	const {
		portal: { isManager, isVendor }
	} = useConfig();
	const { mobile: isMobileScreenSize, tablet: isTabletScreenSize } = screenSize();

	const isMobile = isTabletScreenSize || isMobileScreenSize;
	const barHeight = isManager ? '75px' : '0';

	useEffect(() => {
		/*
			Don't love this, as with all of the ugly layout/navigation/drawer structure I created forever ago. We're bringing back the collapsible nav menu, most of which
			is able to be toggled through the Header. The Header however only renders on Manager so we're manually forcing the nav menu to open whenever that collapse is hidden.
		*/
		if (!isMobile && !props.open) {
			/* 
				For some reason, there was a Redux issue specifically in the Resident portal that was crashing without the setTimeout. Believe it was due to a "SET_LEASE" call being
				dispatched and re-rendering this component. Not super worth investing a ton of time in this at the moment.
			*/
			setTimeout(() => {
				props.showMenu(true);
			});
		}
	}, [isMobile]);

	const toggleModal = () => {
		setMobileModalOpen(prevState => !prevState);
	};

	const toggleDrawer = (event = {}) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
		props.toggleDrawer();
	};

	const handleClick = (path, external, full) => {
		if (external) {
			openExternalLink(path);
		} else {
			if (full) toggleDrawer(); // Close menu on click when backdrop/temporary Drawer is present
		}
	};

	const navigationProps = {
		app: props.app,
		session: props.session,
		items: props.items,
		search: props.search,
		quickActions: props.quickActions,
		portalTypeID: props.portalTypeID,
		portalAccounts: props.portalAccounts,
		badgeCounts: {
			unreadMessageCount: props.unreadMessageCount
		},
		onChangePortal: props.onChangePortal,
		onChangeLease: props.onChangeLease,
		onChangePortfolio: props.onChangePortfolio,
		mobileModalOpen,
		toggleDrawer,
		handleClick,
		toggleModal
	};

	return isMobile && !isManager ? (
		<AppBar open={props.open} toggleDrawer={toggleDrawer} app={props.app} portal={{ isManager, isVendor }} {...navigationProps} />
	) : (
		<>
			{isManager && <MuiHeader toggleDrawer={toggleDrawer} />}

			<MuiDrawer
				anchor="left"
				variant={isMobile ? 'temporary' : 'permanent'}
				onClose={toggleDrawer}
				open={props.open}
				ModalProps={{
					keepMounted: true // Better open performance on mobile.
				}}
				sx={theme => ({
					flexShrink: 0,
					whiteSpace: 'nowrap',
					boxSizing: 'border-box',
					'& .MuiPaper-root': {
						backgroundColor: theme.palette.navigation.background.main,
						border: 'none',
						zIndex: 1000,
						overflowX: 'hidden',
						borderRadius: isMobile && '0px 20px 20px 0px',
						top: !isMobile ? barHeight : '0',
						height: !isMobile ? `calc(100dvh - ${barHeight})` : '100%',
						width: !props.open ? 'auto' : OPEN_DRAWER_WIDTH,
						transition: theme.transitions.create('width', {
							easing: theme.transitions.easing.sharp,
							duration: theme.transitions.duration.enteringScreen
						})
					}
				})}>
				{!isManager && !isMobile ? props?.app?.brandSettings?.fileName ? <CustomerHeader app={props.app} /> : <Header toggleDrawer={toggleDrawer} /> : null}
				{isMobile || props.open ? <Drawer {...navigationProps} full={isMobile && true} /> : <Rail {...navigationProps} />}
			</MuiDrawer>
		</>
	);
}
