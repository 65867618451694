import { matchPath, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Badge, Button, buttonClasses, Icon, ListItemButton, ListItemText } from '@mui/material';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Box } from '@mui/system';

import useConfig from 'Lib/hooks/api/useConfig';
import CurrentAccount from 'Lib/muiComponents/dev/CurrentAccount';

import Submenu from './components/Submenu';
import User from './components/user/User';

export default function Drawer({
	app,
	matchExact = false,
	full,
	items,
	badgeCounts = {},
	portalAccounts,
	portalTypeID,
	session,
	toggleDrawer,
	handleClick,
	onChangeLease,
	onChangePortal,
	onChangePortfolio
}) {
	const location = useLocation();

	const {
		portal: { isManager }
	} = useConfig();

	const NavigationButton = ({ badgeID, external, icon, label, path, submenu, submenuItems = [] }) => {
		const selected = path === '/' ? location.pathname == '/' : !!matchPath(location.pathname, { path, exact: matchExact });

		return submenu ? (
			<Submenu icon={icon} label={label} submenuItems={submenuItems} full={full} handleClick={handleClick} />
		) : (
			<ListItemButton
				disableGutters
				variant="nav"
				to={path}
				selected={selected}
				component={external ? 'div' : Link}
				sx={{ ...(full && { width: '100%', ml: 0 }) }}
				onClick={() => handleClick(path, external, full)}>
				<ListItemIcon variant="regular" sx={{ ml: full ? 2 : selected ? 2 : 4 }}>
					<Icon>{icon}</Icon>
				</ListItemIcon>
				<ListItemText primary={label} />
				<Badge color="primary" badgeContent={badgeCounts[badgeID]} sx={theme => ({ '& .BaseBadge-badge': { mr: 5, color: `${theme.palette.common.white} !important` } })} />
			</ListItemButton>
		);
	};

	const DrawerHeader = () => {
		if (full) {
			return (
				<Box sx={{ display: 'flex', bgcolor: 'common.background', justifyContent: 'flex-end' }}>
					<Button
						onClick={toggleDrawer}
						sx={{
							mt: 4,
							mr: 2,
							borderRadius: '8px',
							color: 'success.main',
							textTransform: 'capitalize',
							fontWeight: 'regular',
							[`& .${buttonClasses.endIcon}`]: { alignItems: 'center' },
							'& .material-symbols-rounded': theme => ({ fontSize: `${theme.typography.pxToRem(24)} !important` })
						}}
						endIcon={<span className="material-symbols-rounded">menu_open</span>}>
						Close Menu
					</Button>
				</Box>
			);
		}
	};

	return (
		<>
			<DrawerHeader />
			<List
				component="nav"
				sx={{
					display: 'flex',
					height: '100%',
					overflowY: 'auto',
					bgcolor: 'navigation.background.main',
					my: 2,
					ml: 2,
					py: 1
				}}>
				<Box sx={{ width: '100%', whiteSpace: 'break-spaces', mr: 4, ...(full && { width: '372px' }) }}>
					{items.map(({ badgeID, external, icon, label, path, items: submenuItems, submenu }, index) => {
						const navigationProps = { badgeID, external, icon, label, path, submenu, submenuItems };

						return <NavigationButton key={index} {...navigationProps} />;
					})}
				</Box>
			</List>
			{isManager ? (
				<CurrentAccount />
			) : (
				<User
					open={true}
					full={full}
					app={app}
					portalAccounts={portalAccounts}
					portalTypeID={portalTypeID}
					session={session}
					toggleDrawer={toggleDrawer}
					onChangeLease={onChangeLease}
					onChangePortfolio={onChangePortfolio}
					onChangePortal={onChangePortal}
				/>
			)}
		</>
	);
}
