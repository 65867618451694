import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Popover, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import PortalTypes from 'Lib/constants/app/portalTypes';
import { useSession } from 'Lib/hooks/api/useSession';
import Logo from 'Lib/muiComponents/common/Logo';
import { DEFAULT_FONT_STYLE } from 'Lib/styles/variables';
import parseInitials from 'Lib/utilities/parseInitials';

import SelectLease from './SelectLease';
import SelectPortal from './SelectPortal';
import SelectPortfolio from './SelectPortfolio';
import { EllipsesButton, Info, InfoLabel, UserAvatar, UserContainer, UserInfoBox, Username, UserOptionButton } from './StyledComponents';

export default function User({ app, portalAccounts = [], portalTypeID, open, full, session, placement = '', toggleDrawer, onChangeLease, onChangePortfolio, onChangePortal }) {
    const { account = {}, lease = {}, user = {}, portal = {}, portals = [], portfolio = {}, brandSettings = {} } = app;
    const isOwner = portalTypeID == PortalTypes.Owner;
    const isVendor = portalTypeID == PortalTypes.Vendor;
    const isManager = portalTypeID == PortalTypes.Manager;
    const isTenant = portalTypeID == PortalTypes.Tenant;
    const { logout } = useSession();

    const showPortalSelect = isOwner || isTenant || isVendor;

    const location = useLocation();

    const [anchorElement, setAnchorElement] = useState(null);
    const [portfoliosModalOpen, setPortfoliosModalOpen] = useState(false);
    const [leasesModalOpen, setLeasesModalOpen] = useState(false);
    const [portalsModalOpen, setPortalsModalOpen] = useState(false);

    const header = placement === 'header';

    useEffect(() => {
        setAnchorElement(null);
    }, [location.pathname]);

    const handleClick = event => {
        if (full) toggleDrawer();
        setAnchorElement(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElement(null);
    };

    const togglePortfoliosModal = () => {
        setPortfoliosModalOpen(prevState => !prevState);
        handleClose();
    };

    const toggleLeasesModal = () => {
        setLeasesModalOpen(prevState => !prevState);
        handleClose();
    };

    const togglePortalsModal = () => {
        setPortalsModalOpen(prevState => !prevState);
        handleClose();
    };

    const openPopover = Boolean(anchorElement);
    const id = openPopover ? 'options-popover' : undefined;

    const username = `${parseInitials(user.firstName)}. ${user.lastName}`;
    const initials = parseInitials(`${user.firstName} ${user.lastName}`);

    const portalProps = {
        isVendor,
        isOwner,
        isTenant,
        open: portalsModalOpen,
        portal,
        portals,
        onChangePortal,
        togglePortalsModal
    };
    const portfolioProps = {
        open: portfoliosModalOpen,
        portal,
        portfolio,
        onChangePortfolio,
        togglePortfoliosModal
    };
    const leaseProps = { open: leasesModalOpen, lease, portal, onChangeLease, toggleLeasesModal };

    const SwitchContacts = () =>
        portals?.length > 1 && (
            <ListItem disablePadding>
                <UserOptionButton onClick={togglePortalsModal}>
                    <ListItemIcon>
                        <span className="material-symbols-rounded">recent_actors</span>
                    </ListItemIcon>
                    <ListItemText primary="Switch Contacts" />
                </UserOptionButton>
            </ListItem>
        );

    const SwitchPortfolios = () =>
        portal?.portfolios?.length > 1 && (
            <ListItem disablePadding>
                <UserOptionButton onClick={togglePortfoliosModal}>
                    <ListItemIcon>
                        <span className="material-symbols-rounded">swap_horiz</span>
                    </ListItemIcon>
                    <ListItemText primary="Switch Portfolios" />
                </UserOptionButton>
            </ListItem>
        );

    const SwitchLeases = () =>
        portal?.leases?.length > 1 && (
            <ListItem disablePadding>
                <UserOptionButton onClick={toggleLeasesModal}>
                    <ListItemIcon>
                        <span className="material-symbols-rounded">swap_horiz</span>
                    </ListItemIcon>
                    <ListItemText primary="Switch Leases" />
                </UserOptionButton>
            </ListItem>
        );

    const ManagerActions = () => (
        <>
            <ListItem disablePadding>
                <UserOptionButton data-cy="settings-button" data-testid="settings-option-button" component={Link} to="/settings">
                    <ListItemIcon>
                        <span className="material-symbols-rounded">manage_accounts</span>
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                </UserOptionButton>
            </ListItem>
            <ListItem disablePadding>
                <UserOptionButton data-testid="billing-option-button" component={Link} to="/billing">
                    <ListItemIcon>
                        <span className="material-symbols-rounded">request_quote</span>
                    </ListItemIcon>
                    <ListItemText primary="Billing" />
                </UserOptionButton>
            </ListItem>
        </>
    );

    const VendorActions = () => <SwitchContacts />;
    const TenantActions = () => (
        <>
            <SwitchLeases />
            <SwitchContacts />
        </>
    );
    const OwnerActions = () => (
        <>
            <SwitchPortfolios />
            <SwitchContacts />
        </>
    );

    const OwnerInformation = () => (
        <Box display="flex" flexDirection="column" gap={4}>
            <Box>
                <InfoLabel>Owner</InfoLabel>
                <Info>{portal?.owner?.name}</Info>
            </Box>
            <Box>
                <InfoLabel>Portfolio</InfoLabel>
                <Info>{portfolio?.name}</Info>
            </Box>
        </Box>
    );

    const VendorInformation = () => (
        <Box>
            <InfoLabel>Vendor</InfoLabel>
            <Info>{portal?.vendor?.name}</Info>
        </Box>
    );

    const TenantInformation = () => (
        <Box display="flex" flexDirection="column" gap={4}>
            <Box>
                <InfoLabel>Resident</InfoLabel>
                <Info>{portal?.tenant?.name}</Info>
            </Box>
            <Box>
                <InfoLabel>Lease</InfoLabel>
                <Info>{lease.unit.address}</Info>
                {lease.unit.address2 && <Info>{lease.unit.address2}</Info>}
            </Box>
        </Box>
    );

    const OptionsContainer = () => {
        const Options = () => (
            <List disablePadding>
                <Typography
                    sx={{
                        fontStyle: DEFAULT_FONT_STYLE,
                        fontSize: '10px',
                        fontWeight: 'regular',
                        textTransform: 'uppercase',
                        letterSpacing: '1.5px',
                        ml: 3,
                        mt: 2,
                        width: '100%'
                    }}
                >
                    User Account
                </Typography>

                {/* OWNER ACTIONS */}
                {isOwner && <OwnerActions />}

                {/* TENANT ACTIONS */}
                {isTenant && <TenantActions />}

                {/* VENDOR ACTIONS */}
                {isVendor && <VendorActions />}

                {portalAccounts.length > 1 && (
                    <ListItem disablePadding>
                        <UserOptionButton component="a" href={`https://account${process.env.DOMAIN}/auth/select`} rel="noopener noreferrer">
                            <ListItemIcon>
                                <span className="material-symbols-rounded">switch_account</span>
                            </ListItemIcon>
                            <ListItemText primary="Switch Portals" />
                        </UserOptionButton>
                    </ListItem>
                )}

                <ListItem disablePadding>
                    <UserOptionButton data-testid="profile-option-button" component="a" href={`https://account${process.env.DOMAIN}/me`} target="_blank" rel="noopener noreferrer">
                        <ListItemIcon>
                            <span className="material-symbols-rounded">account_circle</span>
                        </ListItemIcon>
                        <ListItemText primary="My Profile" />
                    </UserOptionButton>
                </ListItem>

                {/* MANAGER ACTIONS */}
                {isManager && <ManagerActions />}

                <ListItem disablePadding>
                    <UserOptionButton component="a" href="https://help.rentvine.com/" target="https://help.rentvine.com/" rel="noopener noreferrer">
                        <ListItemIcon>
                            <span className="material-symbols-rounded">help</span>
                        </ListItemIcon>
                        <ListItemText primary="Support" />
                    </UserOptionButton>
                </ListItem>

                <ListItem disablePadding>
                    <UserOptionButton
                        component="a"
                        href="https://www.rentvine.com/blog/category/release-notes"
                        target="https://www.rentvine.com/blog/category/release-notes"
                        rel="noopener noreferrer"
                    >
                        <ListItemIcon>
                            <span className="material-symbols-rounded">new_releases</span>
                        </ListItemIcon>
                        <ListItemText primary="Release Notes" />
                    </UserOptionButton>
                </ListItem>

                <Divider sx={{ border: '1px solid #8F8F8F' }} />

                <ListItem disablePadding>
                    <UserOptionButton onClick={logout}>
                        <ListItemIcon>
                            <span className="material-symbols-rounded">logout</span>
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </UserOptionButton>
                </ListItem>
            </List>
        );

        return full ? (
            <Drawer
                anchor="bottom"
                onClose={handleClose}
                open={openPopover}
                sx={{
                    '& .MuiDrawer-paper': {
                        borderRadius: '16px 16px 0px 0px',
                        overflow: 'hidden'
                    }
                }}
            >
                <Options />
            </Drawer>
        ) : (
            <Popover
                id={id}
                data-testid="user-options-popover"
                open={openPopover}
                anchorEl={anchorElement}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                sx={{
                    '& .MuiPaper-root': {
                        border: '1px solid #545454',
                        width: '227px'
                    }
                }}
            >
                <Options />
            </Popover>
        );
    };

    return open ? (
        <UserContainer open full={full}>
            <UserInfoBox>
                <Box display="flex" flexDirection="column" minWidth={0} flex={1}>
                    <Username data-testid="username" noWrap>
                        {username}
                    </Username>
                    {account && <Info noWrap>{account.name}</Info>}
                </Box>
                <EllipsesButton data-cy="actions-button" data-testid="ellipses-actions-button" aria-describedby={id} aria-label="user options" onClick={handleClick}>
                    <span className="material-symbols-rounded">more_vert</span>
                </EllipsesButton>
            </UserInfoBox>
            {account && (
                <>
                    {isOwner && <OwnerInformation />}
                    {isVendor && <VendorInformation />}
                    {isTenant && <TenantInformation />}
                </>
            )}
            {brandSettings.fileName && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap'
                    }}
                >
                    <Typography fontSize={13} letterSpacing="normal">
                        Powered by
                    </Typography>
                    <Logo fullLogo sx={{ width: 93, height: '100%' }} />
                </Box>
            )}
            <OptionsContainer />
            {isOwner && <SelectPortfolio {...portfolioProps} />}
            {isTenant && <SelectLease {...leaseProps} />}
            {showPortalSelect && <SelectPortal {...portalProps} />}
        </UserContainer>
    ) : (
        <UserContainer sx={{ ml: 0, backgroundColor: header && 'unset' }} open={false}>
            <IconButton
                data-testid="icon-actions-button"
                aria-label="user avatar button"
                sx={theme => ({
                    width: '48px',
                    height: '48px',
                    padding: '0 !important',
                    margin: !header && '12px 22px 12px 16px',
                    backgroundColor: header && `${theme.palette.disabled.main}`
                })}
                onClick={handleClick}
            >
                <UserAvatar
                    sx={
                        header && {
                            backgroundColor: 'unset',
                            fontSize: '0.9rem',
                            fontWeight: '700'
                        }
                    }
                >
                    {initials}
                </UserAvatar>
            </IconButton>
            <OptionsContainer />
            {isOwner && <SelectPortfolio {...portfolioProps} />}
            {isTenant && <SelectLease {...leaseProps} />}
            {showPortalSelect && <SelectPortal {...portalProps} />}
        </UserContainer>
    );
}
