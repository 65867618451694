/*
 * Icon library here: https://fonts.google.com/icons
 * All icons with use the same className of "material-symbols-rounded" (rounded version)
 *
 * `permissionsFlag` is only added if the item itself is only visible based off of Redux permissions.
 * e.g. Ledger item will run a permission check in `App.js` to check the value `isLiveLedgerEnabled`
 * and only render if the value is `1` or `true`; see `navigationPermissions.js` for more info there.
 */
const NAVIGATION_ITEMS = [
	{
		label: 'Dashboard',
		path: '/',
		icon: 'dashboard',
		matchExact: true
	},
	{
		label: 'Portfolios',
		path: '/portfolios',
		icon: 'folder_copy'
	},
	{
		label: 'Property',
		submenu: true,
		items: [
			{
				belongsTo: 'Property',
				label: 'Properties',
				path: '/properties'
			},
			{
				belongsTo: 'Property',
				label: 'Associations',
				path: '/associations'
			},
			{
				belongsTo: 'Property',
				label: 'Marketing',
				path: '/marketing/listings'
			}
		],
		icon: 'home_work'
	},
	{
		label: 'Screening',
		submenu: true,
		items: [
			{
				label: 'Dashboard',
				path: '/dashboards/screening'
			},
			{
				label: 'Applications',
				path: '/screening/applications'
			},
			{
				label: 'Payments',
				path: '/screening/payments'
			},
			{
				label: 'Invitations',
				path: '/screening/invitations'
			}
		],
		icon: 'find_in_page'
	},
	{
		label: 'Leases',
		submenu: true,
		items: [
			{
				label: 'Dashboard',
				path: '/dashboards/leases'
			},
			{
				label: 'Leases',
				path: '/leases'
			}
		],
		icon: 'assignment'
	},
	{
		label: 'Rentsign',
		path: '/documents/transactions',
		icon: 'edit_note'
	},
	{
		label: 'Accounting',
		submenu: true,
		items: [
			{
				label: 'Dashboard',
				path: '/dashboards/accounting'
			},
			{
				label: 'Money In',
				path: '/accounting/receivables'
			},
			{
				label: 'Money Out',
				path: '/accounting/payables'
			},
			{
				label: 'Banking',
				path: '/accounting/banking'
			},
			{
				label: 'Transactions',
				path: '/accounting/transactions'
			},
			{
				label: 'Diagnostics',
				path: '/accounting/diagnostics'
			},
			{
				label: 'Manager',
				path: '/accounting/manager/dashboard'
			}
		],
		icon: 'attach_money'
	},
	{
		label: 'Contacts',
		submenu: true,
		items: [
			{
				label: 'Owners',
				path: '/contacts/owners'
			},
			{
				label: 'Tenants',
				path: '/contacts/tenants'
			},
			{
				label: 'Vendors',
				path: '/maintenance/vendors'
			},
			{
				label: 'Messages',
				path: '/contacts/messages'
			}
		],
		icon: 'import_contacts'
	},
	{
		label: 'Maintenance',
		submenu: true,
		items: [
			{
				label: 'Dashboard',
				path: '/dashboards/maintenance'
			},
			{
				label: 'Work Orders',
				path: '/maintenance/work-orders'
			},
			{
				label: 'Projects',
				path: '/maintenance/projects'
			},
			{
				label: 'Inspections',
				path: '/maintenance/inspections'
			}
		],
		icon: 'build'
	},
	{
		label: 'Reports',
		path: '/reports',
		icon: 'monitoring'
	}
];

export default NAVIGATION_ITEMS;
