import { Tooltip as MuiTooltip } from '@mui/material';

export default function Tooltip({ children, isExpanded = false, label }) {
    return (
        <MuiTooltip
            arrow
            title={label}
            placement="right"
            disableHoverListener={isExpanded}
            PopperProps={{
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 12]
                        }
                    }
                ]
            }}
        >
            {children}
        </MuiTooltip>
    );
}
