import queryString from 'query-string';

import { SORT_DIRECTIONS } from 'Lib/components/common/table/SortableHeader';

export default function filterParameters(defaultFilters = {}) {
    const locallyStoredTablePageSize = JSON.parse(localStorage?.getItem('tablePageSize') ?? 15);
    const { orderBy, page = 1, pageSize = locallyStoredTablePageSize, ...rest } = queryString.parse(location.search);
    const combinedParameters = { ...defaultFilters, page, pageSize, ...rest };
    const [key, direction] = orderBy?.split('|') ?? [];

    if (key && (direction === SORT_DIRECTIONS.ASCENDING || direction === SORT_DIRECTIONS.DESCENDING)) {
        combinedParameters['orderBy'] = `${key}|${direction}`;
    }

    return {
        stringified: queryString.stringify(combinedParameters),
        parsed: combinedParameters
    };
}
