// @ts-check
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, ClickAwayListener, Icon, IconButton, MenuItem, MenuList, Paper, Popper, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as actions from 'ROOT/apps/manager/src/services/ui/actions';

import stopPropagation from 'Lib/utilities/stopPropagation';

import { DRAWER_CONTENT_TYPES } from '../../../SideDrawer';
import { NotificationsContext } from '../../context';
import { useUpdateNotifications } from '../../data/useNotifications';
import { StyledBadge, StyledListItemButton } from '../../StyledComponents';
import TimeStamp from '../TimeStamp';

/** @param {{notification: NotificationItem}} param0  */
export default function ListItem({ notification }) {
	const theme = useTheme();
	const dispatch = useDispatch();
	const { dispatch: contextDispatch } = useContext(NotificationsContext);
	const { markAsRead, markAsUnread } = useUpdateNotifications();

	const [anchorEl, setAnchorEl] = useState(null);
	const pinned = useSelector(state => state.ui.isDrawerOpen);
	const pinnable = useMediaQuery(theme.breakpoints.up('xl')) && !pinned;

	const moreOptionsOpen = Boolean(anchorEl);

	return (
		<StyledListItemButton
			onClick={() => {
				if (notification.isRead == '0') {
					markAsRead(notification.userSystemNotificationID);
				}
				contextDispatch({
					type: 'SET_SELECTED_NOTIFICATION',
					payload: { ...notification, isRead: '1' }
				});
			}}>
			<Box sx={{ flex: '0 0 30px' }}>{notification.isRead == '0' && <StyledBadge color="info" variant="dot" />}</Box>
			<Box
				sx={{
					flex: '1 0',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'stretch'
				}}>
				<Typography
					component="span"
					sx={{
						fontWeight: theme.typography.fontWeightMedium,
						fontSize: theme.typography.pxToRem(16)
					}}>
					{notification.subject}
				</Typography>
				<TimeStamp notification={notification} />
			</Box>
			<Box sx={{ flex: '50px 0' }}>
				<IconButton
					sx={{
						width: '48px',
						height: '48px',
						top: '-16px',
						right: '-16px',
						position: 'relative'
					}}
					onClick={e => {
						stopPropagation(e);

						if (anchorEl) {
							setAnchorEl(null);
						} else {
							setAnchorEl(e.nativeEvent.target);
						}
					}}>
					more_vert
				</IconButton>
				<Popper open={moreOptionsOpen} anchorEl={anchorEl} placement="bottom-start" disablePortal sx={{ zIndex: '2' }}>
					<Paper>
						<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
							<MenuList>
								<MenuItem
									onClick={e => {
										stopPropagation(e);

										if (notification.isRead == '1') {
											markAsUnread(notification.userSystemNotificationID);
										} else {
											markAsRead(notification.userSystemNotificationID);
										}
										setAnchorEl(null);
									}}>
									<Icon>{notification.isRead == '0' ? 'done' : 'mark_email_unread'}</Icon>
									{notification.isRead == '0' ? 'Mark as Read' : 'Mark as Unread'}
								</MenuItem>
								{pinnable && (
									<MenuItem
										onClick={e => {
											stopPropagation(e);

											dispatch(actions.pinDrawer(DRAWER_CONTENT_TYPES.NOTIFICATIONS));
											contextDispatch({
												type: 'PIN_NOTIFICATION',
												payload: notification
											});
											contextDispatch({
												type: 'SET_VIEW',
												payload: 'pinned'
											});

											setAnchorEl(null);
										}}>
										<Icon>push_pin</Icon>
										Pin Notification
									</MenuItem>
								)}
							</MenuList>
						</ClickAwayListener>
					</Paper>
				</Popper>
			</Box>
		</StyledListItemButton>
	);
}
