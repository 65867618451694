import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import queryString from 'query-string';

import filterParameters from 'Lib/utilities/filterParameters';

export const SORT_DIRECTIONS = { ASCENDING: 'asc', DESCENDING: 'desc' };

/**
 * This component allows filtering on most of our lists via clicking it's child headers if an entry is
 * provided via the sortableHeaders dictionary pairs
 *
 * @param {function} onSort - The fetch function that feeds the Table List
 * @param {object} sortableHeaders - A dictionary of legible keys followed by the key that the backend is able to sort off of
 *
 * @example
 *
 *       <SortableHeader
 *          onSort={fetchList}
 *          sortableHeaders={{
 *            Unit: { key: 'unit.address' },
 *            Tenants: { key: 'lease.tenants' },
 *            'Start Date': { key: null }, <--- null key indicates a column that CANNOT be sorted
 *            'End Date': { key: 'lease.endDate' },
 *            Status: { key: 'lease.leaseStatusID' }
 *          }}
 *       />
 */
const SortableHeader = ({ sortableHeaders, onSort }) => {
    const history = useHistory();

    const [sort, setSort] = useState({});

    useEffect(() => {
        const { orderBy } = queryString.parse(history.location.search);
        const [key, direction] = orderBy?.split('|') ?? [];

        setSort({ key, direction });
    }, []);

    const handleSort = key => {
        const { parsed } = filterParameters();

        // First sorted direction will be ascending
        if (!sort.direction || sort.direction === SORT_DIRECTIONS.DESCENDING || key !== sort.key) {
            setSort({
                key,
                direction: SORT_DIRECTIONS.ASCENDING
            });

            history.replace({
                search: queryString.stringify({
                    ...parsed,
                    orderBy: `${key}|${SORT_DIRECTIONS.ASCENDING}`
                })
            });

            onSort();
        } else {
            setSort({
                key,
                direction: SORT_DIRECTIONS.DESCENDING
            });

            history.replace({
                search: queryString.stringify({
                    ...parsed,
                    orderBy: `${key}|${SORT_DIRECTIONS.DESCENDING}`
                })
            });

            onSort();
        }
    };

    return (
        <thead>
            <tr>
                {Object.entries(sortableHeaders).map(([key, value], index) => {
                    const sortableTableHeader = value.key;

                    // Only render SortIcon if an entry exists for it in the sortableHeaders dictionary and switch based off sort direction
                    const SortIcon = () => {
                        if (!sortableTableHeader) return null;
                        else if (sort.key == sortableTableHeader && sort.direction === SORT_DIRECTIONS.DESCENDING) return <i className="fad fa-sort-down" />;
                        else if (sort.key == sortableTableHeader && sort.direction === SORT_DIRECTIONS.ASCENDING) return <i className="fad fa-sort-up" />;
                        else return <i className="fad fa-sort" />;
                    };

                    return (
                        <th
                            key={index}
                            className="table-head--hover"
                            onClick={() => (sortableTableHeader ? handleSort(sortableTableHeader) : null)}
                            style={{ cursor: sortableTableHeader ? 'pointer' : '' }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                {key}
                                <SortIcon />
                            </div>
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

export default SortableHeader;
