import { createBrowserHistory } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';

import createComposedMiddleware from './middlewares';
import createRootReducer from './reducers';

const initialState = {};

const { createReduxHistory, routerReducer, routerMiddleware } =
	createReduxHistoryContext({
		history: createBrowserHistory(),
		reduxTravelling: true // for redux-dev-tools
	});

const customMiddleware = createComposedMiddleware(routerMiddleware);

const store = configureStore({
	reducer: createRootReducer(routerReducer),
	preloadedState: initialState,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false
		}).concat(customMiddleware)
});

export const history = createReduxHistory(store);

export default store;
