import * as Sentry from '@sentry/react';

import isEmpty from './isEmpty';

const statuses = [400, 401, 403, 404, 500, 501, 504];
const sentryIgnoreRegex = new RegExp(
	`(?:${statuses.join('|')}|axioserror)`,
	'i'
);

export const initializeSentry = () => {
	if (process.env.APP_ENV == 'production' || process.env.APP_ENV == 'qa' || process.env.APP_ENV == 'staging') {
		Sentry.init({
			dsn: 'https://44e958f095e143b2b3a4918bc420acb3@o523155.ingest.sentry.io/5635150',
			integrations: [
				Sentry.browserTracingIntegration(),
				Sentry.replayIntegration({
					maskAllText: false,
					maskAllInputs: false
				})
			],
			environment: process.env.APP_ENV,
			// We recommend adjusting this value in production, or using tracesSampler for finer control
			tracesSampleRate: process.env.APP_ENV === 'production' ? 0.8 :  1.0,
			// If the entire session is not sampled, use the below sample rate to sample
			// sessions when an error occurs.
			replaysOnErrorSampleRate: 1.0,
			ignoreErrors: sentryIgnoreList,
			beforeSend(event, hint) {
				return filterSentryErrors(event, hint);
			},
			beforeBreadcrumb(breadcrumb, hint) {
				return hint.level == 'warn' ? null : breadcrumb;
			}
		});
	}
	/*
		To test Sentry in dev, uncomment the code below and feel free to add different sort of handling in the `beforeSend` method.
		There are several props/options in the init option that you can find in their documentation.
	*/
	// Sentry.init({
	//     dsn: 'https://44e958f095e143b2b3a4918bc420acb3@o523155.ingest.sentry.io/5635150',
	//     integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration({ maskAllText: false, maskAllInputs: false })],
	//     environment: 'development',
	//     // We recommend adjusting this value in production, or using tracesSampler for finer control
	//     tracesSampleRate: 1.0,
	//     // If the entire session is not sampled, use the below sample rate to sample
	//     // sessions when an error occurs.
	//     replaysOnErrorSampleRate: 1.0,
	//     ignoreErrors: sentryIgnoreList,
	//     beforeSend(event, hint) {
	//         return filterSentryErrors(event, hint);
	//     },
	//     beforeBreadcrumb(breadcrumb, hint) {
	//         return hint.level == 'warn' ? null : breadcrumb;
	//     }
	// });
};

export const sentryIgnoreList = [
	// Generic Errors
	'Unauthorized',
	'401',
	'timeout',
	'Network Error',
	'Error: Network Error',
	'Network request failed',
	'Failed to fetch',
	'NetworkError',
	'ResizeObserver',
	// Chrome extensions
	/extensions\//i,
	/^chrome:\/\//i,
	/^chrome-extension:\/\//i,
	// Libraries
	'froala'
];

export const filterSentryErrors = (event, hint) => {
	console.log('event', event);
	console.log('hint', hint);
	if (!isEmpty(event.exception.values)) {
		// If the error is just a rejected promise, filter out. Usually don't care about this error
		if (event.exception.values[0].value.includes('promise')) {
			return null;
		}
	}

	if (sentryIgnoreRegex.test(hint?.originalException?.message)) {
		/*
            We don't care about codes inside of 'statuses' since the API handles responses sent back. This most often occurs when a user creates/edits
            information and doesn't fill out required fields. API responds saying which fields are problematic. We also will get a
            spammed with tons of these events from users inputting information so here we can omit these events from sending to Sentry.
        */
		return null;
	}

	if (
		hint?.originalException?.message
			?.toLowerCase()
			.includes('minified redux')
	) {
		return null;
	}

	return event;
};
