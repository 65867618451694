import { useEffect, useState } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Icon, ListItemButton, ListItemIcon, ListItemText, Popover } from '@mui/material';
import List from '@mui/material/List';
import { alpha, styled } from '@mui/system';

import Tooltip from './components/Tooltip';

const NavigationButton = ({ external, icon, expanded, label, path, matchExact, submenu, submenuItems, handleClick }) => {
	const history = useHistory();
	const [anchorElement, setAnchorElement] = useState(null);

	const handleClose = () => {
		setAnchorElement(null);
	};

	const selected = !!matchPath(location.pathname, { path, exact: matchExact });
	const isSubmenuSelected = submenu && !!matchPath(location.pathname, { path: submenuItems.map(item => item.path) });
	const isExpanded = expanded === label;
	const navigationPopover = Boolean(anchorElement);
	const id = navigationPopover ? 'simple-popover' : undefined;

	return (
		<>
			<Tooltip label={label} isExpanded={isExpanded}>
				<RoundedIcon
					open={navigationPopover}
					submenu={submenu}
					selected={selected || isSubmenuSelected}
					onClick={e => {
						setAnchorElement(e.currentTarget);
						handleClick(path, external);
					}}
					to={path}
					component={external || submenu ? 'div' : Link}>
					<ListItemIcon variant="large" sx={{ ml: -1 }}>
						<Icon>{icon}</Icon>
					</ListItemIcon>
				</RoundedIcon>
			</Tooltip>
			{submenu && (
				<Popover
					id={id}
					open={navigationPopover}
					anchorEl={anchorElement}
					onClick={handleClose}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left'
					}}
					sx={{ left: '20px', '& .MuiPaper-root': { boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)', bgcolor: 'navigation.background.selected', borderRadius: 1 } }}>
					<ListItemText
						sx={{
							mt: 4,
							mb: 2,
							borderBottom: 1,
							borderBottomColor: 'navigation.span.main',
							'& .MuiTypography-root': {
								color: 'navigation.span.main',
								fontWeight: theme => theme.typography.fontWeightSemiBold,
								mb: 4,
								pl: 4
							}
						}}
						primary={label}
					/>
					{submenuItems.map((item, itemIndex) => {
						const isItemSelected = !!matchPath(location.pathname, { path: item.path });

						return (
							<SubmenuButton key={itemIndex} selected={isItemSelected} onClick={() => history.push(item.path)} to={item.path} component={Link}>
								<ListItemText primary={item.label} />
							</SubmenuButton>
						);
					})}
				</Popover>
			)}
		</>
	);
};

export default function Rail({ items, handleClick }) {
	const location = useLocation();

	const [expanded, setExpanded] = useState('');

	useEffect(() => {
		setExpanded('');
	}, [location.pathname]);

	return (
		<List
			component="nav"
			sx={{
				display: 'block',
				height: '100%',
				overflowY: 'auto',
				overflowX: 'hidden',
				bgcolor: 'navigation.background.main',
				ml: 2,
				pb: 0
			}}>
			{items.map(({ external, icon, badgeID, label, path, items: submenuItems, submenu, matchExact }, index) => {
				const navigationProps = { external, icon, badgeID, label, path, submenu, submenuItems };

				return <NavigationButton {...navigationProps} key={index} expanded={expanded} matchExact={matchExact} handleClick={handleClick} />;
			})}
		</List>
	);
}

const RoundedIcon = styled(ListItemButton, { shouldForwardProp: prop => prop !== 'submenu' })(({ theme }) => ({
	borderRadius: '16px',
	marginLeft: theme.spacing(1),
	marginBottom: theme.spacing(2),
	height: '56px',
	width: '56px',
	'&.Mui-selected': {
		backgroundColor: `${theme.palette.navigation.background.hover} !important`,
		span: { color: theme.palette.navigation.span.selected },
		'&:hover': { outline: `2px solid ${alpha(theme.palette.navigation.span.selected, 0.2)}`, outlineOffset: '2px', span: { color: theme.palette.navigation.span.hover } },
		border: `1px solid ${theme.palette.navigation.span.selected}`,
		borderStyle: 'double',
		outline: `2px solid ${alpha(theme.palette.navigation.span.selected, 0.2)}`,
		outlineOffset: '1px'
	},
	'&:hover': { backgroundColor: theme.palette.navigation.background.hover },
	'& .material-symbols-rounded': {
		color: 'common.black',
		fontSize: '32px'
	}
}));

const SubmenuButton = styled(ListItemButton)(({ theme, selected }) => ({
	height: '48px',
	borderRadius: '8px',
	marginBottom: theme.spacing(2),
	marginLeft: theme.spacing(2),
	marginRight: theme.spacing(2),
	span: { color: theme.palette.navigation.span.selected, marginLeft: selected ? 0 : theme.spacing(2), width: '100%' },
	'&.Mui-selected': {
		borderLeft: `8px solid ${theme.palette.navigation.span.selected}`,
		backgroundColor: `${theme.palette.navigation.background.main} !important`,
		span: { color: theme.palette.navigation.span.selected },
		'&:hover': { outline: `2px solid ${alpha(theme.palette.navigation.span.selected, 0.2)}`, outlineOffset: '2px' }
	},
	'&:hover': { backgroundColor: theme.palette.common.background },
	'&:last-child': {
		marginBottom: theme.spacing(2)
	}
}));
