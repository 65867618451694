// @ts-check

import { useSelector } from 'react-redux';
import { Icon, IconButton, Stack } from '@mui/material';
import MuiBadge, { badgeClasses } from '@mui/material/Badge';

import { useBadgeCount } from 'Lib/components/layout/components/notifications/data/useNotifications';
import Notifications from 'Lib/components/layout/components/notifications/index';
import QuickActions from 'Lib/components/layout/components/QuickActions';
import User from 'Lib/components/layout/components/user/User';
import useConfig from 'Lib/hooks/api/useConfig';
import { ModalTrigger } from 'Lib/muiComponents/modal';

export default function PrimaryActions() {
	const app = useSelector(state => state.app);
	const session = useSelector(state => state.session);

	const {
		portal: { isManager }
	} = useConfig();
	const badgeCount = isManager ? useBadgeCount() : 0;

	return (
		<Stack direction="row" spacing={1}>
			<ModalTrigger
				name="Ai Assist"
				sx={{
					width: '3rem',
					height: '3rem',
					borderRadius: '50%',
					'&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
				}}>
				<Icon sx={{ color: 'black' }}>auto_awesome</Icon>
			</ModalTrigger>
			<Notifications>
				{({ onClick }) => (
					<IconButton aria-label="notifications" onClick={onClick}>
						{badgeCount && badgeCount > 0 ? (
							<MuiBadge
								variant="dot"
								color="info"
								overlap="circular"
								sx={{
									[`& .${badgeClasses.badge}`]: { mr: 0 }
								}}>
								notifications
							</MuiBadge>
						) : (
							'notifications'
						)}
					</IconButton>
				)}
			</Notifications>
			<QuickActions>
				{({ onClick }) => (
					<IconButton aria-label="recently viewed" onClick={onClick}>
						rocket
					</IconButton>
				)}
			</QuickActions>
			<User
				app={app}
				portalAccounts={app.portalAccounts}
				portalTypeID={app.portalTypeID}
				session={session}
				placement={'header'}
			/>
		</Stack>
	);
}
