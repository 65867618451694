import { useQuery } from '@tanstack/react-query';

const queryKey = ['leases', 'statuses'];

const useLeaseStatuses = () => {
	return useQuery({
		queryKey: [...queryKey],
		staleTime: Infinity,
		refetchOnWindowFocus: false,
		retry: false
	});
};

export default useLeaseStatuses;
