import DataTable from 'Lib/utilities/DataTable';
import initializePendo from 'Lib/utilities/pendoUtils';

import Acl from './acl';
import * as actions from './actions';
import * as app from './selectors';

import agent from '~/agent';
import store from '~/store';

export const initialize = () => {
    agent.request
        .get('init')
        .then(response => {
            const { account, user, users, role, permissions, portalAccounts, listingFeeds, systemAlerts } = response.data;
            const acl = new Acl();

            try {
                permissions.forEach(component => {
                    const accessList = [];

                    component.actions.forEach(action => {
                        accessList.push(action.id);
                    });

                    acl.addComponent(component.id, accessList);
                });

                if (role && role.permissions) {
                    const access = JSON.parse(role.permissions);

                    Object.keys(access).forEach(name => {
                        acl.allow(name, access[name]);
                    });
                }
            } catch (error) {}

            const usersTable = new DataTable(users, user => user.userID);

            initializePendo({ account, user, role });
            
            store.dispatch(
                actions.init({
                    isInitialized: true,
                    hasError: false,
                    errorMessage: null,
                    domain: process.env.DOMAIN,
                    user,
                    role,
                    permissions,
                    portalAccounts,
                    systemAlerts,
                    listingFeeds,
                    acl,
                    account,
                    users: usersTable
                })
            );
        })
        .catch(error => {
            if (error.response?.status == 401) {
                store.dispatch(
                    actions.init({
                        isInitialized: false,
                        hasError: false,
                        doLogin: true,
                        hasAuthenticationError: false,
                        errorMessage: 'Could not initialize the application.'
                    })
                );
            } else {
                store.dispatch(
                    actions.init({
                        isInitialized: false,
                        hasError: true,
                        hasAuthenticationError: true,
                        errorMessage: 'Could not initialize the application.'
                    })
                );
            }
        });
};

/**
 * API
 */
export const callApi = (promise, exclude = false) => {
    if (!exclude) {
        store.dispatch(actions.startApiCall());
    }

    promise
        .catch(error => {
            // TODO: check the status code is 401
            if (error.response.status == 401) {
                // store.dispatch(actions.logout());
            }
        })
        .finally(() => {
            if (!exclude) {
                store.dispatch(actions.endApiCall());
            }
        });
};

/**
 * Has Permission
 */
export const hasPermission = (component, action) => {
    if (!component || !action) return false;
    return app.get().acl.isAllowed(component, action);
};

/**
 * Dismiss System Alert
 */
export const dismissSystemAlerts = () => {
    store.dispatch(actions.dismissSystemAlerts());
};
