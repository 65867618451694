// @ts-check
import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Container } from '@mui/material';
import * as Sentry from '@sentry/react';

import SystemAlert from 'Lib/components/common/SystemAlert';
import SentryErrorBoundary from 'Lib/components/errors/SentryErrorBoundary';
import Navigation from 'Lib/components/layout/Navigation';
import NotFound from 'Lib/components/layout/NotFound';
import PortalTypes from 'Lib/constants/app/portalTypes';
import QueryClientProvider from 'Lib/QueryClient';
import { setTitle } from 'Lib/utilities/setTitle';

import '~/scss/app.scss';
import 'react-toastify/dist/ReactToastify.css';

import { loadApplication } from '~/actions/app';
import { dismissAlert } from '~/api/app';
import { search } from '~/api/search';
import AIAssist from '~/components/aiAssist';
import GlobalSearch from '~/components/globalSearch';
import NAVIGATION_ITEMS from '~/constants/layout/NavigationItems';
import Authenticated from '~/containers/Authenticated';
import AppLayout from '~/containers/layouts/App';
import routes from '~/routes';
import * as session from '~/services/session';
import { showMenu } from '~/services/ui/actions';
import { history } from '~/store';

const mapStateToProps = state => ({
	app: state.app,
	systemAlerts: state.app.systemAlerts,
	portalAccounts: state.app.portalAccounts,
	pathname: state.router.location.pathname,
	router: state.router,
	ui: state.ui
});

const mapDispatchToProps = {
	loadApplication,
	showMenu
};

class App extends Component {
	state = {
		showModal: false,
		userLoggedIn: false,
		isTimedOut: false
	};

	componentDidMount = () => {
		document.title = setTitle(this.props.router.location);
	};

	componentDidUpdate = prevProps => {
		if (this.props.router.location != prevProps.router.location) {
			const location = this.props.router.location.pathname.split('/');

			if (isNaN(location[location.length - 1]) || location[location.length - 1] == '') {
				document.title = setTitle(this.props.router.location);
			}
		}
	};

	render() {
		const { app, ui, pathname, portalAccounts } = this.props;

		if (app.account && app.user) {
			Sentry.setUser({
				accountName: app.account.name,
				accountID: app.account.accountID,
				accountStatusID: app.account.accountStatusID,
				companyID: app.account.companyID,
				subdomain: app.account.subdomain,
				userID: app.user.userID,
				email: app.user.email,
				username: `${app.user.firstName} ${app.user.lastName}`
			});
		}

		return (
			<>
				<Router history={history}>
					<Container
						disableGutters
						maxWidth={false}
						sx={theme => ({
							bgcolor: 'common.background',
							[theme.breakpoints.up('md')]: {
								height: '100vh',
								overflow: 'hidden'
							}
						})}>
						<Authenticated>
							<QueryClientProvider>
								<Navigation
									quickActions
									app={app}
									portalAccounts={portalAccounts}
									portalTypeID={PortalTypes.Manager}
									items={NAVIGATION_ITEMS}
									open={ui.menuVisible}
									session={session}
									search={search}
									showMenu={this.props.showMenu}
									toggleDrawer={() => {
										this.props.showMenu(!ui.menuVisible);
									}}
								/>
								<Switch>
									{routes.map(route => {
										if (route.redirect) return <Redirect key={pathname} from={route.path} {...route.redirect} />;

										return (
											<Route
												key={pathname}
												path={route.path}
												exact={true}
												render={props => (
													<AppLayout>
														<GlobalSearch.Modal />
														<AIAssist.Modal />

														<SentryErrorBoundary>
															<route.component route={props} routes={route.routes} />
															<SystemAlert alerts={this.props.systemAlerts} onDismiss={dismissAlert} />
														</SentryErrorBoundary>
													</AppLayout>
												)}
											/>
										);
									})}
									<Route route="*" component={NotFound} />
								</Switch>
							</QueryClientProvider>
						</Authenticated>
					</Container>
				</Router>
				<ToastContainer
					position="bottom-right"
					theme="colored"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnVisibilityChange
					draggable
					pauseOnHover
				/>
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
